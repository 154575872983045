
import { SERVICE_DELAY } from "@/app/infrastructures/misc/RolePermission";
import {
  checkRolePermission,
  formatDateWithoutTime,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import statusListData from "@/app/infrastructures/misc/common-library/StatusListData";
import { Vue } from "vue-class-component";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ServiceDelayController } from "@/app/ui/controllers/ServiceDelayController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { ServiceDelayDetail } from "@/domain/entities/ServiceDelay";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";

export default class CargoConfigurationList extends Vue {
  mounted() {
    this.fetchList();
  }
  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  params = {
    search: "",
    status: ""
  };
  onSearch(value: string) {
    this.params.search = value;
    this.listData.pagination.page = 1;
    this.fetchList();
  }
  clearSearch() {
    this.params.search = "";
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  get statusData(): OptionsClass[] {
    return statusListData(["active", "inactive"]);
  }
  onSelectStatus(item: OptionsClass): void {
    this.params.status = item.value;
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  listData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchList() {
    this.listData.loading = true;
    try {
      this.listData = await ServiceDelayController.getList(
        new ApiRequestList({
          page: this.listData.pagination.page,
          limit: this.listData.pagination.limit,
          search: this.params.search,
          status: this.params.status
        })
      );
    } catch (err) {
      this.listData.message = parsingErrorResponse(err).type;
    } finally {
      this.listData.loading = false;
    }
  }

  columns = [
    {
      name: "ID",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: ServiceDelayDetail) =>
        `<span class='text-left text-red-lp-200 flex justify-center'>${item.id}</span>`
    },
    {
      name: "Nama Kendala",
      styleHead: "w-1/5 text-left whitespace-no-wrap",
      render: (item: ServiceDelayDetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center'>${item.name}</span>`
    },
    {
      name: "3LC",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: ServiceDelayDetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center'>${item.cityCode}</span>`
    },
    {
      name: "Kecamatan",
      styleHead: "w-1/4 text-left whitespace-no-wrap",
      render: (item: ServiceDelayDetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center'>${item.districts}</span>`
    },
    {
      name: "Tanggal Mulai",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      render: (item: ServiceDelayDetail) =>
        `<span class='text-black-lp-300 flex justify-center'>${formatDateWithoutTime(
          item.startDate
        )}</span>`
    },
    {
      name: "Tanggal Berakhir",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      render: (item: ServiceDelayDetail) =>
        `<span class='text-black-lp-300 flex justify-center'>${formatDateWithoutTime(
          item.endDate
        )}</span>`
    },
    {
      name: "Status",
      styleHead: "w-2/20 text-left text-black-lp-300 whitespace-no-wrap",
      render: (item: ServiceDelayDetail) =>
        ChipsStyles({ status: item.statusData })
    }
  ];

  goToCreate() {
    this.$router.push("/network/service-delay/add");
  }
  onClickRow(item: ServiceDelayDetail) {
    this.$router.push(`/network/service-delay/${item.id}`);
  }

  get isCreateAble() {
    return checkRolePermission(SERVICE_DELAY.CREATE);
  }
  get isDetailAble() {
    return checkRolePermission(SERVICE_DELAY.DETAIL);
  }
}
