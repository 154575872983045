import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-6 px-4 w-3/4" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_ChipsV2 = _resolveComponent("ChipsV2")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    onBack: _ctx.goBack,
    title: _ctx.detailData.name,
    "custom-class": "px-0",
    loading: _ctx.detailEntities.loading,
    error: !!_ctx.detailEntities.message,
    errorType: _ctx.detailEntities.message,
    onTryAgain: _ctx.fetchDetail
  }, {
    data: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Title, { label: "Detail Area Keterlambatan" }),
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            label: "ID",
            value: _ctx.detailData.id
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Nama Kendala",
            value: _ctx.detailData.name
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "3LC",
            value: _ctx.detailData.cityCode
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Kecamatan",
            value: _ctx.detailData.districts
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Catatan",
            value: _ctx.detailData.remarks,
            class: "col-span-2"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Tanggal Mulai",
            value: 
              _ctx.detailData.formatDate
                ? _ctx.detailData.formatDate(_ctx.detailData.startDate)
                : ''
            
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Tanggal Berakhir",
            value: 
              _ctx.detailData.formatDate
                ? _ctx.detailData.formatDate(_ctx.detailData.endDate)
                : ''
            
          }, null, 8, ["value"])
        ])
      ])
    ]),
    "sub-data": _withCtx(() => [
      _createVNode(_component_DataWrapper, { label: "Status" }, {
        default: _withCtx(() => [
          _createVNode(_component_ChipsV2, {
            label: _ctx.detailData.statusData
          }, null, 8, ["label"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "Tanggal Dibuat",
        value: 
          _ctx.detailData.formatDate
            ? _ctx.detailData.formatDate(_ctx.detailData.createdAt)
            : ''
        ,
        caption: _ctx.detailData.createdBy
      }, null, 8, ["value", "caption"]),
      _createVNode(_component_DataWrapper, {
        label: "Tanggal Diubah",
        value: 
          _ctx.detailData.formatDate
            ? _ctx.detailData.formatDate(_ctx.detailData.updatedAt)
            : ''
        ,
        caption: _ctx.detailData.updatedBy
      }, null, 8, ["value", "caption"]),
      (_ctx.isEditable)
        ? (_openBlock(), _createBlock(_component_LpButton, {
            key: 0,
            title: "Ubah",
            onClick: _ctx.goEdit,
            "custom-class": "w-44",
            textColor: "red-lp-100",
            outline: ""
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onBack", "title", "loading", "error", "errorType", "onTryAgain"]))
}